<template>
  <BCard class="border p-0 col">
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity="0.7"
      rounded="sm"
    >
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-start gap-5 align-items-center">
            <div
              class="p-[3px] rounded"
              :class="classIcon"
            >
              <img
                :src="icon"
                alt="revenue"
                width="20px"
              >
            </div>
            <span class="text-[16px] text-[#626262]">{{ title }}</span>
          </div>
          <div
            class="d-flex justify-content-end col-6 p-0"
            style="height: 37px;"
          >
            <VSelect
              v-model="dateRevenue"
              :reduce="e => e.value"
              :options="optionsDate"
              label="text"
              :clearable="false"
              class="!text-[#333] text-[12px] col-12 p-0"
              @input="getData"
            />
          </div>
        </div>
        <div class="d-flex align-items-center gap-5">
          <span class="text-black text-[24px] font-semibold">{{ dataAnalytic.total || '0' }}</span>
          <span class="increased text-[12px] px-[10px] py-[4px] rounded rounded-pill text-nowrap">+ {{ dataAnalytic.today || '0' }} Today</span>
        </div>
        <span class="text-[#626262] mt-2">Yesterday : {{ dataAnalytic.yesterday || '0' }}</span>
      </div>
    </BOverlay>
  </BCard>
</template>
<script>
import VSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    VSelect,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    classIcon: {
      type: String,
      required: true,
    },
    dataAnalytic: {
      type: Object,
      default: () => null,
      required: true,
    },
    loading: {
      type: Boolean,
      reqired: true,
      default: false,
    },
    optionsDate: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      dateRevenue: 'this_month',
    }
  },
  methods: {
    getData() {
      this.$emit('getDataAnalytic', this.value, this.dateRevenue)
    },
  },
}
</script>
<style scoped>
.decreased {
    background-color: #E31A1A;
    color: white;
    width: fit-content;
}
.increased {
    background-color: #BEFCDE;
    color: #34A770;
    width: fit-content;
}
</style>
