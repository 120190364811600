<template>
  <div class="mt-3">
    <BCard class="border p-0 col-12 bg-revenue">
      <BOverlay
        :show="isLoadingRevenue"
        spinner-variant="primary"
        variant="light"
        blur="0.1"
        opacity="0.5"
        rounded="sm"
      >
        <div class="d-flex justify-content-between flex-md-row flex-column-reverse">
          <div class="d-flex flex-column justify-content-start">
            <div class="d-flex gap-5 align-items-center">
              <div class="bg-[#BEE6FC] p-[3px] rounded">
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/usd-coin-blue.svg"
                  alt="revenue"
                  width="20px"
                >
              </div>
              <span class="text-[16px] text-[#626262]">Revenue</span>
            </div>
            <div class="d-flex align-items-center gap-3">
              <span class="text-black text-[38px] font-[600]">{{ IDR(dataAnalyticRevenue.total || 0) }}</span>
              <span
                v-if="dataAnalyticRevenue.percentage !== 0"
                :class="badgeClass"
                class="px-[10px] py-[4px] rounded rounded-pill"
              >{{ dataAnalyticRevenue.percentage || 0 }}%</span>
            </div>
            <span class="text-[#626262] mt-2">Last month revenue : {{ IDR(dataAnalyticRevenue.last_month || 0) }}</span>
          </div>
          <div
            class="d-flex justify-content-end col-md-4 col-lg-2 col-12 mb-1 p-0"
            style="height: 37px; background-color: white; border-radius: 0.3rem"
          >
            <VSelect
              v-model="dateRevenue"
              :reduce="e => e.value"
              :options="optionsDate"
              label="text"
              :clearable="false"
              class="!text-[#333] col-12 p-0"
              @input="getDataAnalytic('revenue', dateRevenue)"
            />
          </div>
        </div>
      </BOverlay>
    </BCard>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
      <CardAnalytic
        v-for="(item, index) in analytics"
        :key="index"
        v-bind="item"
        :options-date="optionsDate"
        :loading="getLoadForValue(item.value)"
        :data-analytic="getDataForValue(item.value)"
        @getDataAnalytic="getDataAnalytic"
      />
    </div>
  </div>
</template>

<script>
import { collaboratorAxiosIns } from '@/libs/axios'
import VSelect from 'vue-select'
import { IDR } from '@/libs/currency'
import CardAnalytic from './CardAnalytic.vue'

export default {
  components: { VSelect, CardAnalytic },
  data() {
    return {
      IDR,
      isLoadingRevenue: false,
      isLoadingReg: false,
      isLoadingVerif: false,
      isLoadingPickup: false,
      isLoadingStarter: false,
      isLoadingPro: false,
      isLoadingEnterprise: false,
      dateRevenue: 'this_month',
      optionsDate: [
        { text: 'This Month', value: 'this_month' },
        { text: 'This Week', value: 'this_week' },
        { text: 'Last Week', value: 'last_week' },
      ],
      analytics: [
        {
          icon: 'https://storage.googleapis.com/komerce/assets/icons/profile-2user-blue.svg', classIcon: 'bg-[#BEE6FC]', title: 'All User Register', value: 'registered_user',
        },
        {
          icon: 'https://storage.googleapis.com/komerce/assets/icons/profile-tick-green.svg', classIcon: 'bg-[#DCF3EB]', title: 'All User Verify', value: 'verified_user',
        },
        {
          icon: 'https://storage.googleapis.com/komerce/assets/icons/truck-tick-yellow.svg', classIcon: 'bg-[#FFF2E2]', title: 'Pickup', value: 'request_pickup',
        },
        {
          icon: 'https://storage.googleapis.com/komerce/assets/icons/house-starter.svg', classIcon: 'bg-[#E2E2E2]', title: 'Starter', value: 'user_starter',
        },
        {
          icon: 'https://storage.googleapis.com/komerce/assets/icons/buliding-pro.svg', classIcon: 'bg-[#FEF2CD]', title: 'Pro', value: 'user_pro',
        },
        {
          icon: 'https://storage.googleapis.com/komerce/assets/icons/buildings-enterprise.svg', classIcon: 'bg-[#FCD4BE]', title: 'Enterprise', value: 'user_enterprise',
        },
      ],
      dataAnalyticRevenue: {},
      dataRegister: {},
      dataVerify: {},
      dataPickup: {},
      dataStarter: {},
      dataPro: {},
      dataEnterprise: {},
    }
  },
  computed: {
    badgeClass() {
      return this.dataAnalyticRevenue.percentage > 0 ? 'increased-revenue' : 'decreased'
    },
  },
  mounted() {
    this.getDataAnalytic('revenue', 'this_month')
    this.analytics.forEach(item => this.getDataAnalytic(item.value, 'this_month'))
  },
  methods: {
    getDataForValue(value) {
      switch (value) {
        case 'registered_user': return this.dataRegister
        case 'verified_user': return this.dataVerify
        case 'request_pickup': return this.dataPickup
        case 'user_starter': return this.dataStarter
        case 'user_pro': return this.dataPro
        case 'user_enterprise': return this.dataEnterprise
        default: return {}
      }
    },
    getLoadForValue(value) {
      switch (value) {
        case 'registered_user': return this.isLoadingReg
        case 'verified_user': return this.isLoadingVerif
        case 'request_pickup': return this.isLoadingPickup
        case 'user_starter': return this.isLoadingStarter
        case 'user_pro': return this.isLoadingPro
        case 'user_enterprise': return this.isLoadingEnterprise
        default: return {}
      }
    },
    async getDataAnalytic(type, filter) {
      const loadingMap = {
        revenue: 'isLoadingRevenue',
        registered_user: 'isLoadingReg',
        verified_user: 'isLoadingVerif',
        request_pickup: 'isLoadingPickup',
        user_starter: 'isLoadingStarter',
        user_pro: 'isLoadingPro',
        user_enterprise: 'isLoadingEnterprise',
      }

      this[loadingMap[type]] = true

      try {
        const { data } = await collaboratorAxiosIns.get(
          `/user/api/v1/admin/users/analytics/${type}`,
          { params: { filter_type: filter } },
        )

        const dataMap = {
          revenue: 'dataAnalyticRevenue',
          registered_user: 'dataRegister',
          verified_user: 'dataVerify',
          request_pickup: 'dataPickup',
          user_starter: 'dataStarter',
          user_pro: 'dataPro',
          user_enterprise: 'dataEnterprise',
        }

        this[dataMap[type]] = data.data
        this[loadingMap[type]] = false
      } catch {
        this[loadingMap[type]] = false
        this.$toast_error({ message: 'Failed to fetch data' })
      }
    },

  },
}
</script>

<style scoped>
.bg-revenue {
  background: url('https://storage.googleapis.com/komerce/assets/bg_revenue_green.png') no-repeat right bottom, #DCF3EB;
}
.decreased {
    background-color: #E31A1A;
    color: white;
    width: fit-content;
}
.increased-revenue {
    background-color: #34A770;
    color: white;
    width: fit-content;
}
</style>
